import { useEffect, useState } from 'react'

import { IoMdClose } from 'react-icons/io'
import { Editor } from '@tinymce/tinymce-react'
import { toast } from 'react-toastify'

import { Upload } from '../Upload'
import { UploadEdit } from '../UploadEdit'
import { UploadGallery } from '../UploadGallery'

import { BannerAconteceNoParque } from '../BannerAconteceNoParque'
import { BannerTemNoParque } from '../BannerTemNoParque'
import { BannerInstitucional } from '../BannerInstitucional'
import { TemNoParquePages } from '../TemNoParquePages'

import api from '../../services/api'

import { ModalBG, ModalContainer, Title, Content, ModalButton } from './styles'

export function ModalEdit(props) {
 const route = props.name
 const title = props.title
 const folder = props.folder

 const token = localStorage.getItem('token')

 //const [banners, setBanners] = useState([])
 //const [pages, setPages] = useState([])
 const [fields, setFields] = useState([])
 const [categories, setCategories] = useState([])
 const [checked, setChecked] = useState(true)
 const [loading, setLoading] = useState(false)
 const [showGallery, setShowGallery] = useState(true)
 const [selectedFile, setSelectedFile] = useState()
 const [selectedFileMobile, setSelectedFileMobile] = useState()
 const [selectedGallery, setSelectedGallery] = useState([])
 const [showDropzone, setShowDropZone] = useState(false)
 const [showDropzoneMobile, setShowDropZoneMobile] = useState(false)
 const [showDropzoneGallery, setShowDropZoneGallery] = useState(false)
 const [text, setText] = useState('')
 const [bio, setBio] = useState('')

 const [formData, setFormData] = useState({})

 const handleInputChange = e => {
  const { name, value } = e.target

  setFormData({ ...formData, [name]: value })
 }

 const handleDropzone = () => {
  setShowDropZone(true)
 }

 const handleDropzoneMobile = () => {
  setShowDropZoneMobile(true)
 }

 const handleDropzoneGallery = () => {
  setShowDropZoneGallery(true)
 }

 useEffect(() => {
  async function loadEdit() {
   const response = await api.get(`/${route}/edit/${props.id}`, {
    headers: {
     Authorization: `Bearer ${token}`
    }
   })
   setFields([response.data])
  }

  loadEdit()
 }, [props.id, route, token])

 async function handleEdit(e) {
  e.preventDefault()

  const getFields = await api.get(`${route}/edit/${props.id}`, {
   headers: {
    Authorization: `Bearer ${token}`
   }
  })

  const response = getFields.data

  try {
   const status =
    checked === false && response.status === 0
     ? 1
     : checked === true && response.status === 1
     ? 1
     : 0
   const show_gallery =
    showGallery === false && response.show_gallery === 0
     ? 1
     : showGallery === true && response.show_gallery === 1
     ? 1
     : 0

   const data = new FormData()

   data.append(
    'publication',
    formData.publication != null ? formData.publication : response.publication
   )
   data.append(
    'expiration',
    formData.expiration != null ? formData.expiration : response.expiration
   )
   data.append('b_id', formData.b_id != null ? formData.b_id : response.b_id)
   data.append('p_id', formData.p_id != null ? formData.p_id : response.p_id)
   data.append('type', formData.type != null ? formData.type : response.type)
   data.append(
    'segmento',
    formData.segmento != null ? formData.segmento : response.segmento
   )
   data.append(
    'title',
    formData.title != null ? formData.title : response.title
   )

   data.append('text', !text ? response.text : text)
   data.append('bio', !bio ? response.bio : bio)

   data.append(
    'btn_title',
    formData.btn_title != null ? formData.btn_title : response.btn_title
   )
   data.append('link', formData.link != null ? formData.link : response.link)

   data.append('name', formData.name != null ? formData.name : response.name)
   data.append(
    'category',
    formData.category != null ? formData.category : response.category
   )
   data.append(
    'subcategory',
    formData.subcategory != null ? formData.subcategory : response.subcategory
   )
   data.append(
    'subcategory2',
    formData.subcategory2 != null
     ? formData.subcategory2
     : response.subcategory2
   )
   data.append(
    'floor',
    formData.floor != null ? formData.floor : response.floor
   )
   data.append(
    'phone',
    formData.phone != null ? formData.phone : response.phone
   )
   data.append('site', formData.site != null ? formData.site : response.site)
   data.append(
    'instagram',
    formData.instagram != null ? formData.instagram : response.instagram
   )
   data.append(
    'facebook',
    formData.facebook != null ? formData.facebook : response.facebook
   )
   data.append(
    'whatsapp',
    formData.whatsapp != null ? formData.whatsapp : response.whatsapp
   )
   data.append(
    'login',
    formData.login != null ? formData.login : response.login
   )
   data.append(
    'password',
    formData.password != null ? formData.password : response.password
   )
   data.append(
    'galleryTitle',
    formData.galleryTitle != null
     ? formData.galleryTitle
     : response.galleryTitle
   )
   data.append(
    'videosTitle',
    formData.videosTitle != null ? formData.videosTitle : response.videosTitle
   )
   data.append(
    'titleGallery',
    formData.titleGallery != null
     ? formData.titleGallery
     : response.titleGallery
   )
   data.append(
    'videos',
    formData.videos != null ? formData.videos : response.videos
   )
   data.append('page', formData.page != null ? formData.page : response.page)
   data.append('show_gallery', show_gallery)
   data.append('status', status)

   if (selectedFile) {
    data.append('file', selectedFile)
   }

   if (selectedFileMobile) {
    data.append('file_mobile', selectedFileMobile)
   }

   if (selectedGallery) {
    for (let i = 0; i < selectedGallery.length; i++) {
     data.append(`gallery${[i]}`, selectedGallery[i])
    }
   }

   setLoading(true)

   const update = await api.post(`/${route}/update/${props.id}`, data, {
    headers: {
     Accept: 'application/json',
     'Access-Control-Allow-Origin': '*',
     'Access-Control-Allow-Credentials': true,
     'Access-Control-Allow-Headers':
      'Origin, X-Requeted-With, Content-Type, Accept, Authorization, RBR',
     'Content-Type': 'application/json',
     'X-HTTP-Method-Override': 'PUT',
     Authorization: `Bearer ${token}`
    }
   })

   const message = await update.data.message

   if (message) {
    toast.warning(message, {
     position: 'top-right',
     autoClose: 5000,
     hideProgressBar: false,
     closeOnClick: true,
     pauseOnHover: true,
     draggable: true,
     progress: undefined
    })
   } else {
    props.isClose()

    toast.success(update.data, {
     position: 'top-right',
     autoClose: 5000,
     hideProgressBar: false,
     closeOnClick: true,
     pauseOnHover: true,
     draggable: true,
     progress: undefined
    })

    props.isLoad()
   }
  } catch (error) {
   console.error(error)

   if (error) {
    props.isClose()

    toast.error('😟 Erro ao atualizar as informações', {
     position: 'top-right',
     autoClose: 5000,
     hideProgressBar: false,
     closeOnClick: true,
     pauseOnHover: true,
     draggable: true,
     progress: undefined
    })
   }
  }
 }

 useEffect(() => {
  return () => {
   setLoading(false)
  }
 }, [])

 const handleEditorChange = e => {
  setText(e.target.getContent())
 }

 const handleBioChange = e => {
  setBio(e.target.getContent())
 }

 useEffect(() => {
  async function loadCategories() {
   const response = await api.get('segmento')
   setCategories(response.data)
  }
  loadCategories()
 }, [])

 return (
  <>
   <ModalBG>
    <ModalContainer>
     <Title>
      Editar {title} {props.id}
      <IoMdClose size={25} color="#63a269" onClick={props.isClose} />
     </Title>
     <Content onSubmit={handleEdit} enctype="multipart/form-data">
      {fields &&
       fields.map(field => (
        <div key={field.id} className="first">
         {field.page && (
          <div>
           <label htmlFor="page">Página</label>
           <input
            type="text"
            onChange={handleInputChange}
            name="page"
            defaultValue={field.page}
           />
          </div>
         )}
         {(field.file === '' || field.file) && (
          <div key={field.id} className={title}>
           <label htmlFor="imagem">Imagem</label>
           {showDropzone ? (
            <Upload onUpload={setSelectedFile} />
           ) : (
            <img
             src={`${process.env.REACT_APP_IMAGE_URL}/uploads/${folder}/${field.file}`}
             alt={field.title}
             onClick={handleDropzone}
            />
           )}
          </div>
         )}
         {(field.file_mobile === '' || field.file_mobile) && (
          <div className={title}>
           <label htmlFor="imagem">Imagem Mobile</label>
           {showDropzoneMobile ? (
            <UploadEdit onUpload={setSelectedFileMobile} />
           ) : (
            <img
             src={`${process.env.REACT_APP_IMAGE_URL}/uploads/${folder}/${field.file_mobile}`}
             alt={field.title}
             onClick={handleDropzoneMobile}
            />
           )}
          </div>
         )}
         {field.type && (
          <div>
           <label htmlFor="type">Tipo de Banner</label>
           <select
            onChange={handleInputChange}
            name="type"
            defaultValue={field.type}
           >
            <option value="">Selecione o tipo do banner</option>
            <option value="banner foto + texto">banner foto + texto</option>
            <option value="banner cinema">banner cinema</option>
            <option value="banner destaques">banner destaques</option>
            <option value="banner lojas">banner lojas</option>
            <option value="mini banners">mini banners</option>
           </select>
          </div>
         )}
         {field.segmento && (
          <div>
           <label htmlFor="segmento">Tipo de Segmento</label>
           <select
            onChange={handleInputChange}
            name="segmento"
            defaultValue={field.segmento}
           >
            <option value="">Selecione o tipo de segmento</option>
            <option value="Lojas">Lojas</option>
            <option value="Alimentação">Alimentação</option>
           </select>
          </div>
         )}
         {field.b_id && (
          <div>
           <label htmlFor="b_id">Banner</label>
           <select
            onChange={handleInputChange}
            name="b_id"
            defaultValue={field.b_id}
           >
            <option value="">Selecione o banner da página</option>
            {/* {banners.map(banner => (
             <option key={banner.id} value={banner.id}>
              {banner.title}
             </option>
            ))} */}
            {route === 'acontece' ? <BannerAconteceNoParque /> : ''}
            {route === 'temnoparque' ? <BannerTemNoParque /> : ''}
            {route === 'institucional' ? <BannerInstitucional /> : ''}
           </select>
          </div>
         )}
         {field.p_id && (
          <div>
           <label htmlFor="p_id">Página</label>
           <select
            onChange={handleInputChange}
            name="p_id"
            defaultValue={field.p_id}
           >
            <option value="">
             Selecione a página que deseja adicionar está galeria
            </option>
            {/* {pages.map(page => (
             <option key={page.id} value={page.id}>
              {page.page}
             </option>
            ))} */}
            {route === 'temnoparque-galerias' ? <TemNoParquePages /> : ''}
           </select>
          </div>
         )}

         {field.title || field.title === '' ? (
          <div>
           <label htmlFor="title">Título</label>
           <input
            type="text"
            onChange={handleInputChange}
            name="title"
            defaultValue={field.title}
           />
          </div>
         ) : (
          ''
         )}
         {field.name || field.name === '' ? (
          <div>
           <label htmlFor="nome">Nome</label>
           <input
            type="text"
            onChange={handleInputChange}
            name="name"
            defaultValue={field.name}
           />
          </div>
         ) : (
          ''
         )}
         {field.login && (
          <div>
           <label htmlFor="login">Login</label>
           <input
            type="text"
            onChange={handleInputChange}
            name="login"
            defaultValue={field.login}
           />
          </div>
         )}
         {field.password && (
          <div>
           <label htmlFor="password">Password</label>
           <input
            type="password"
            onChange={handleInputChange}
            name="password"
            defaultValue={field.password}
           />
          </div>
         )}
         {(field.category === '' || field.category) && (
          <div>
           <label htmlFor="categoria">Categoria</label>
           <select onChange={handleInputChange} name="category">
            <option defaultValue={field.category}>{field.category}</option>
            {categories.map(category => (
             <option key={category.id} value={category.name}>
              {category.name}
             </option>
            ))}
           </select>
          </div>
         )}
         {(field.subcategory === '' || field.subcategory) && (
          <div>
           <label htmlFor="subcategoria">Subcategoria</label>
           <select onChange={handleInputChange} name="subcategory">
            <option defaultValue={field.subcategory}>
             {field.subcategory}
            </option>
            {categories.map(category => (
             <option key={category.id} value={category.name}>
              {category.name}
             </option>
            ))}
           </select>
          </div>
         )}
         {(field.subcategory2 === '' || field.subcategory2) && (
          <div>
           <label htmlFor="subcategoria2">Subcategoria 2</label>
           <select onChange={handleInputChange} name="subcategory2">
            <option defaultValue={field.subcategory2}>
             {field.subcategory2}
            </option>
            {categories.map(category => (
             <option key={category.id} value={category.name}>
              {category.name}
             </option>
            ))}
           </select>
          </div>
         )}
         {(field.text === '' || field.text) && (
          <div>
           <label htmlFor="title">Texto</label>
           <Editor
            initialValue={field.text}
            apiKey="ytk2nal3kl3124n4czr5atbbpyga60c15hvqu30mdtibt2qt"
            init={{
             toolbar: 'bold italic',
             branding: false,
             plugins: 'link media emoticons',
             contextmenu: 'link media emoticons'
            }}
            onChange={handleEditorChange}
           />
          </div>
         )}
         {(field.bio === '' || field.bio) && (
          <div>
           <label htmlFor="bio">Benefício</label>
           <Editor
            initialValue={field.bio}
            apiKey="ytk2nal3kl3124n4czr5atbbpyga60c15hvqu30mdtibt2qt"
            init={{
             toolbar: 'bold italic',
             branding: false,
             plugins: 'link media emoticons',
             contextmenu: 'link media emoticons'
            }}
            onChange={handleBioChange}
           />
          </div>
         )}
         {(field.floor === '' || field.floor) && (
          <div>
           <label htmlFor="piso">Piso</label>
           <input
            type="text"
            onChange={handleInputChange}
            name="floor"
            defaultValue={field.floor}
           />
          </div>
         )}
         {(field.phone === '' || field.phone) && (
          <div>
           <label htmlFor="telefone">Telefone</label>
           <input
            type="text"
            onChange={handleInputChange}
            name="phone"
            defaultValue={field.phone}
           />
          </div>
         )}
         {(field.site === '' || field.site) && (
          <div>
           <label htmlFor="site">Site</label>
           <input
            type="text"
            onChange={handleInputChange}
            name="site"
            defaultValue={field.site}
           />
          </div>
         )}
         {(field.instagram === '' || field.instagram) && (
          <div>
           <label htmlFor="instagram">Instagram</label>
           <input
            type="text"
            onChange={handleInputChange}
            name="instagram"
            defaultValue={field.instagram}
           />
          </div>
         )}
         {(field.facebook === '' || field.facebook) && (
          <div>
           <label htmlFor="facebook">Facebook</label>
           <input
            type="text"
            onChange={handleInputChange}
            name="facebook"
            defaultValue={field.facebook}
           />
          </div>
         )}
         {(field.whatsapp === '' || field.whatsapp) && (
          <div>
           <label htmlFor="whatsapp">Whatsapp</label>
           <input
            type="text"
            onChange={handleInputChange}
            name="whatsapp"
            defaultValue={field.whatsapp}
           />
          </div>
         )}
         {field.btn_title && (
          <div>
           <label htmlFor="btn_title">Botão</label>
           <input
            type="text"
            onChange={handleInputChange}
            name="btn_title"
            defaultValue={field.btn_title}
           />
          </div>
         )}
         {field.link || field.link === '' ? (
          <div>
           <label htmlFor="link">Link</label>
           <input
            type="text"
            onChange={handleInputChange}
            name="link"
            defaultValue={field.link}
           />
          </div>
         ) : (
          ''
         )}
         {field.publication || field.publication === '' ? (
          <div className="dateInput">
           <label htmlFor="publication">Data de Publicação</label>
           <input
            type="date"
            onChange={handleInputChange}
            name="publication"
            defaultValue={field.publication}
           />
          </div>
         ) : (
          ''
         )}
         {field.expiration || field.expiration === '' ? (
          <div className="dateInput">
           <label htmlFor="expiration">Data de Expiração</label>
           <input
            type="date"
            onChange={handleInputChange}
            name="expiration"
            defaultValue={field.expiration}
           />
          </div>
         ) : (
          ''
         )}
         {field.show_gallery === 1 && showGallery === true ? (
          <div>
           <label htmlFor="gallery">Galeria de Imagens</label>
           {showDropzoneGallery ? (
            <UploadGallery onUpload={setSelectedGallery} />
           ) : (
            <ul className="listImageGallery">
             {field.gallery.split(',').map((image, index) => (
              <li key={index}>
               <img
                src={`${process.env.REACT_APP_IMAGE_URL}/uploads/${folder}/${image}`}
                alt="Galeria"
                onClick={handleDropzoneGallery}
               />
              </li>
             ))}
            </ul>
           )}
          </div>
         ) : field.show_gallery === 0 && showGallery === false ? (
          <div>
           <label htmlFor="gallery">Galeria de Imagens</label>
           <UploadGallery onUpload={setSelectedGallery} />
          </div>
         ) : (
          ''
         )}
         {(field.show_gallery === 1 && showGallery === true) ||
         field.galleryTitle === '' ? (
          <div>
           <label htmlFor="galleryTitle">Nome da Galeria</label>
           <input
            type="text"
            onChange={handleInputChange}
            name="galleryTitle"
            defaultValue={field.galleryTitle}
           />
          </div>
         ) : (
          ''
         )}
         {field.titleGallery && (
          <div>
           <label htmlFor="titleGallery">Nome da Galeria</label>
           <input
            type="text"
            onChange={handleInputChange}
            name="titleGallery"
            defaultValue={field.titleGallery}
           />
          </div>
         )}
         {field.titleGallery && (
          <div className={title}>
           <label htmlFor="gallery">Galeria de Imagens</label>
           {showDropzoneGallery ? (
            <UploadGallery onUpload={setSelectedGallery} />
           ) : (
            <ul className="listImageGallery">
             {field.gallery.split(',').map((image, index) => (
              <li key={index}>
               <img
                src={`${process.env.REACT_APP_IMAGE_URL}/uploads/${folder}/${image}`}
                alt="Galeria"
                onClick={handleDropzoneGallery}
               />
              </li>
             ))}
            </ul>
           )}
          </div>
         )}
         {field.videos || field.videos === '' ? (
          <div>
           <label htmlFor="videos">
            Vídeos ( Adicionar a hash dos vídeos separadas por vírgula )
           </label>
           <input
            type="text"
            onChange={handleInputChange}
            name="videos"
            defaultValue={field.videos}
           />
          </div>
         ) : (
          ''
         )}
         {field.videosTitle || field.videosTitle === '' ? (
          <div>
           <label htmlFor="videosTitle">Nome da Galeria de Vídeos</label>
           <input
            type="text"
            onChange={handleInputChange}
            name="videosTitle"
            defaultValue={field.videosTitle}
           />
          </div>
         ) : (
          ''
         )}
         {field.show_gallery != null && (
          <div>
           <label htmlFor="show_gallery">Adicionar galeria</label>
           <input
            type="checkbox"
            onChange={() => setShowGallery(!showGallery)}
            defaultValue={field.show_gallery}
            checked={field.show_gallery === 1 ? showGallery : !showGallery}
           />
           {field.show_gallery === 1 && showGallery === true
            ? 'Ativo'
            : field.show_gallery === 0 && showGallery === false
            ? 'Ativo'
            : 'Inativo'}
          </div>
         )}
         {field.status != null && (
          <div>
           <label htmlFor="status">Status</label>
           <input
            type="checkbox"
            onChange={() => setChecked(!checked)}
            defaultValue={field.status}
            checked={field.status === 1 ? checked : !checked}
           />
           {field.status === 1 && checked === true
            ? 'Ativo'
            : field.status === 0 && checked === false
            ? 'Ativo'
            : 'Inativo'}
          </div>
         )}
        </div>
       ))}
      <div className="btn-submit">
       <ModalButton type="submit">
        {loading ? 'Carregando...' : 'Salvar'}
       </ModalButton>
      </div>
     </Content>
    </ModalContainer>
   </ModalBG>
  </>
 )
}
