import { useState, useEffect } from 'react'

import { IoMdClose } from 'react-icons/io'
import { Editor } from '@tinymce/tinymce-react'
import { toast } from 'react-toastify'

import { Upload } from '../Upload'
import { UploadGallery } from '../UploadGallery'

import { BannerAconteceNoParque } from '../BannerAconteceNoParque'
import { BannerTemNoParque } from '../BannerTemNoParque'
import { BannerInstitucional } from '../BannerInstitucional'
import { TemNoParquePages } from '../TemNoParquePages'

import api from '../../services/api'

import { ModalBG, ModalContainer, Title, Content, ModalButton } from './styles'

export function ModalAdd(props) {
 const fieldsList = props.fields
 const route = props.name
 const title = props.title

 const token = localStorage.getItem('token')

 const [categories, setCategories] = useState([])
 const [checked, setChecked] = useState(true)
 const [showGallery, setShowGallery] = useState(false)
 const [loading, setLoading] = useState(false)
 const [selectedFile, setSelectedFile] = useState()
 const [selectedFileMobile, setSelectedFileMobile] = useState()
 const [selectedGallery, setSelectedGallery] = useState([])
 const [text, setText] = useState('')
 const [bio, setBio] = useState('')

 const [formData, setFormData] = useState({
  publication: '',
  expiration: '',
  b_id: '',
  p_id: '',
  page: '',
  type: '',
  segmento: '',
  title: '',
  btn_title: '',
  link: '',
  name: '',
  category: '',
  subcategory: '',
  subcategory2: '',
  floor: '',
  phone: '',
  site: '',
  instagram: '',
  facebook: '',
  whatsapp: '',
  login: '',
  password: '',
  titleGallery: '',
  galleryTitle: '',
  videosTitle: '',
  videos: ''
 })

 const handleInputChange = e => {
  const { name, value } = e.target

  setFormData({ ...formData, [name]: value })
 }

 async function handleAdd(e) {
  e.preventDefault()

  try {
   const {
    publication,
    expiration,
    b_id,
    p_id,
    page,
    type,
    segmento,
    title,
    btn_title,
    link,
    name,
    category,
    subcategory,
    subcategory2,
    floor,
    phone,
    site,
    instagram,
    facebook,
    whatsapp,
    login,
    password,
    titleGallery,
    galleryTitle,
    videosTitle,
    videos
   } = formData

   const status = checked === true ? 1 : 0
   const show_gallery = showGallery === true ? 1 : 0

   const data = new FormData()

   data.append('publication', publication)
   data.append('expiration', expiration)
   data.append('b_id', b_id)
   data.append('p_id', p_id)
   data.append('type', type)
   data.append('segmento', segmento)
   data.append('title', title)
   data.append('text', text)
   data.append('bio', bio)
   data.append('btn_title', btn_title)
   data.append('link', link)
   data.append('page', page)
   data.append('name', name)
   data.append('category', category)
   data.append('subcategory', subcategory)
   data.append('subcategory2', subcategory2)
   data.append('floor', floor)
   data.append('phone', phone)
   data.append('site', site)
   data.append('instagram', instagram)
   data.append('facebook', facebook)
   data.append('whatsapp', whatsapp)
   data.append('login', login)
   data.append('password', password)
   data.append('titleGallery', titleGallery)
   data.append('galleryTitle', galleryTitle)
   data.append('videosTitle', videosTitle)
   data.append('videos', videos)
   data.append('show_gallery', show_gallery)
   data.append('status', status)

   if (selectedFile) {
    data.append('file', selectedFile)
   }

   if (selectedFileMobile) {
    data.append('file_mobile', selectedFileMobile)
   }

   if (selectedGallery) {
    for (let i = 0; i < selectedGallery.length; i++) {
     data.append(`gallery${[i]}`, selectedGallery[i])
    }
   }

   setLoading(true)

   const response = await api.post(`/${route}/insert`, data, {
    headers: {
     Accept: 'application/json',
     'Access-Control-Allow-Origin': '*',
     'Access-Control-Allow-Credentials': true,
     'Access-Control-Allow-Headers':
      'Origin, X-Requeted-With, Content-Type, Accept, Authorization, RBR',
     'Content-Type': 'application/json',
     Authorization: `Bearer ${token}`
    }
   })

   const message = await response.data.message

   if (message) {
    toast.warning(message, {
     position: 'top-right',
     autoClose: 5000,
     hideProgressBar: false,
     closeOnClick: true,
     pauseOnHover: true,
     draggable: true,
     progress: undefined
    })
   } else {
    props.isClose()

    toast.success(response.data, {
     position: 'top-right',
     autoClose: 5000,
     hideProgressBar: false,
     closeOnClick: true,
     pauseOnHover: true,
     draggable: true,
     progress: undefined
    })

    props.isLoad()
   }
  } catch (error) {
   console.error(error)

   if (error) {
    props.isClose()

    toast.error('😟 Erro ao cadastrar as informações', {
     position: 'top-right',
     autoClose: 5000,
     hideProgressBar: false,
     closeOnClick: true,
     pauseOnHover: true,
     draggable: true,
     progress: undefined
    })
   }
  }
 }

 useEffect(() => {
  return () => {
   setLoading(false)
  }
 }, [])

 const handleEditorChange = e => {
  setText(e.target.getContent())
 }

 const handleBioChange = e => {
  setBio(e.target.getContent())
 }

 useEffect(() => {
  async function loadCategories() {
   const response = await api.get('segmento')
   setCategories(response.data)
  }
  loadCategories()
 }, [])

 return (
  <>
   <ModalBG>
    <ModalContainer>
     <Title>
      Adicionar {title}
      <IoMdClose size={25} color="#63a269" onClick={props.isClose} />
     </Title>
     <Content onSubmit={handleAdd} enctype="multipart/form-data">
      {fieldsList.map(field => (
       <div key={field.id} className="first">
        {field.page && (
         <div>
          <label htmlFor="page">Página</label>
          <input type="text" onChange={handleInputChange} name="page" />
         </div>
        )}
        {field.type && (
         <div>
          <label htmlFor="type">Tipo de Banner</label>
          <select onChange={handleInputChange} name="type">
           <option value="">Selecione o tipo do banner</option>
           <option value="banner foto + texto">banner foto + texto</option>
           <option value="banner cinema">banner cinema</option>
           <option value="banner destaques">banner destaques</option>
           <option value="banner lojas">banner lojas</option>
           <option value="mini banners">mini banners</option>
          </select>
         </div>
        )}
        {field.imagem && (
         <div className={title}>
          <label htmlFor="imagem">Imagem</label>
          <Upload onUpload={setSelectedFile} />
         </div>
        )}
        {field.imagemMobile && (
         <div className={title}>
          <label htmlFor="imagem">Imagem Mobile</label>
          <Upload onUpload={setSelectedFileMobile} />
         </div>
        )}
        {field.segmento && (
         <div>
          <label htmlFor="segmento">Tipo de Segmento</label>
          <select onChange={handleInputChange} name="segmento">
           <option value="">Selecione o tipo de segmento</option>
           <option value="Lojas">Lojas</option>
           <option value="Alimentação">Alimentação</option>
          </select>
         </div>
        )}
        {field.b_id && (
         <div>
          <label htmlFor="b_id">Banner</label>
          <select onChange={handleInputChange} name="b_id">
           <option value="">Selecione o banner da página</option>
           {route === 'acontece' ? <BannerAconteceNoParque /> : ''}
           {route === 'temnoparque' ? <BannerTemNoParque /> : ''}
           {route === 'institucional' ? <BannerInstitucional /> : ''}
          </select>
         </div>
        )}
        {field.p_id && (
         <div>
          <label htmlFor="p_id">Página</label>
          <select onChange={handleInputChange} name="p_id">
           <option value="">
            Selecione a página que deseja adicionar está galeria
           </option>
           {route === 'temnoparque-galerias' ? <TemNoParquePages /> : ''}
          </select>
         </div>
        )}
        {field.title && (
         <div>
          <label htmlFor="title">Título</label>
          <input type="text" onChange={handleInputChange} name="title" />
         </div>
        )}
        {field.name && (
         <div>
          <label htmlFor="nome">Nome</label>
          <input type="text" onChange={handleInputChange} name="name" />
         </div>
        )}
        {field.login && (
         <div>
          <label htmlFor="login">Login</label>
          <input type="text" onChange={handleInputChange} name="login" />
         </div>
        )}
        {field.password && (
         <div>
          <label htmlFor="password">Password</label>
          <input type="password" onChange={handleInputChange} name="password" />
         </div>
        )}
        {field.category && (
         <div>
          <label htmlFor="categoria">Categoria</label>
          <select onChange={handleInputChange} name="category">
           <option value="">Selecione a categoria</option>
           {categories.map(category => (
            <option key={category.id} value={category.name}>
             {category.name}
            </option>
           ))}
          </select>
         </div>
        )}
        {field.subcategory && (
         <div>
          <label htmlFor="subcategoria">Subcategoria</label>
          <select onChange={handleInputChange} name="subcategory">
           <option value="">Selecione a categoria</option>
           {categories.map(category => (
            <option key={category.id} value={category.name}>
             {category.name}
            </option>
           ))}
          </select>
         </div>
        )}
        {field.subcategory2 && (
         <div>
          <label htmlFor="subcategoria2">Subcategoria 2</label>
          <select onChange={handleInputChange} name="subcategory2">
           <option value="">Selecione a categoria</option>
           {categories.map(category => (
            <option key={category.id} value={category.name}>
             {category.name}
            </option>
           ))}
          </select>
         </div>
        )}
        {field.text && (
         <div>
          <label htmlFor="text">Texto</label>
          <Editor
           initialValue=""
           apiKey="ytk2nal3kl3124n4czr5atbbpyga60c15hvqu30mdtibt2qt"
           init={{
            toolbar: 'bold italic',
            branding: false,
            plugins: 'link media emoticons',
            contextmenu: 'link media emoticons'
           }}
           onChange={handleEditorChange}
          />
         </div>
        )}
        {field.bio && (
         <div>
          <label htmlFor="bio">Benefício</label>
          <Editor
           initialValue=""
           apiKey="ytk2nal3kl3124n4czr5atbbpyga60c15hvqu30mdtibt2qt"
           init={{
            toolbar: 'bold italic',
            branding: false,
            plugins: 'link media emoticons',
            contextmenu: 'link media emoticons'
           }}
           onChange={handleBioChange}
          />
         </div>
        )}
        {field.floor && (
         <div>
          <label htmlFor="piso">Piso</label>
          <input type="text" onChange={handleInputChange} name="floor" />
         </div>
        )}
        {field.phone && (
         <div>
          <label htmlFor="telefone">Telefone</label>
          <input type="text" onChange={handleInputChange} name="phone" />
         </div>
        )}
        {field.site && (
         <div>
          <label htmlFor="site">Site</label>
          <input type="text" onChange={handleInputChange} name="site" />
         </div>
        )}
        {field.instagram && (
         <div>
          <label htmlFor="instagram">Instagram</label>
          <input type="text" onChange={handleInputChange} name="instagram" />
         </div>
        )}
        {field.facebook && (
         <div>
          <label htmlFor="facebook">Facebook</label>
          <input type="text" onChange={handleInputChange} name="facebook" />
         </div>
        )}
        {field.whatsapp && (
         <div>
          <label htmlFor="whatsapp">Whatsapp</label>
          <input type="text" onChange={handleInputChange} name="whatsapp" />
         </div>
        )}
        {field.btn_title && (
         <div>
          <label htmlFor="btn_title">Botão</label>
          <input type="text" onChange={handleInputChange} name="btn_title" />
         </div>
        )}
        {field.link && (
         <div>
          <label htmlFor="link">Link</label>
          <input type="text" onChange={handleInputChange} name="link" />
         </div>
        )}
        {field.publication && (
         <div className="dateInput">
          <label htmlFor="publication">Data de Publicação</label>
          <input type="date" onChange={handleInputChange} name="publication" />
         </div>
        )}
        {field.expiration && (
         <div className="dateInput">
          <label htmlFor="expiration">Data de Expiração</label>
          <input type="date" onChange={handleInputChange} name="expiration" />
         </div>
        )}
        {field.gallery && showGallery === true && (
         <div className={title}>
          <label htmlFor="gallery">Galeria de Imagens</label>
          <UploadGallery onUpload={setSelectedGallery} />
         </div>
        )}
        {field.gallery && showGallery === true && (
         <div>
          <label htmlFor="galleryTitle">Nome da Galeria</label>
          <input type="text" onChange={handleInputChange} name="galleryTitle" />
         </div>
        )}
        {field.titleGallery && (
         <div>
          <label htmlFor="titleGallery">Nome da Galeria</label>
          <input type="text" onChange={handleInputChange} name="titleGallery" />
         </div>
        )}
        {field.titleGallery && (
         <div className={title}>
          <label htmlFor="gallery">Galeria de Imagens</label>
          <UploadGallery onUpload={setSelectedGallery} />
         </div>
        )}
        {field.videos && (
         <div>
          <label htmlFor="videos">
           Vídeos ( Adicionar a hash dos vídeos separadas por vírgula )
          </label>
          <input type="text" onChange={handleInputChange} name="videos" />
         </div>
        )}
        {field.videosTitle && (
         <div>
          <label htmlFor="videosTitle">Nome da Galeria de Vídeos</label>
          <input type="text" onChange={handleInputChange} name="videosTitle" />
         </div>
        )}
        {field.show_gallery && (
         <div>
          <label htmlFor="show_gallery">Adicionar galeria</label>
          <input
           type="checkbox"
           onChange={() => setShowGallery(!showGallery)}
           value={showGallery}
           checked={showGallery}
          />
          {showGallery === true ? 'Ativo' : 'Inativo'}
         </div>
        )}
        {field.status && (
         <div>
          <label htmlFor="status">Status</label>
          <input
           type="checkbox"
           onChange={() => setChecked(!checked)}
           value={checked}
           checked={checked}
          />
          {checked === true ? 'Ativo' : 'Inativo'}
         </div>
        )}
       </div>
      ))}
      <div className="btn-submit">
       <ModalButton type="submit">
        {loading ? 'Carregando...' : 'Salvar'}
       </ModalButton>
      </div>
     </Content>
    </ModalContainer>
   </ModalBG>
  </>
 )
}
