import { List } from './styles'
import { Link } from 'react-router-dom'

import menu1 from '../../assets/AlterarBanner.svg'
import menu2 from '../../assets/ConteudoPagina.svg'

export function MenuTemNoParque() {
 return (
  <>
   <List>
    <Link to="/tem-no-parque-banner">
     <img src={menu1} alt="Alterar Banner" />
     <p>Alterar Banner</p>
    </Link>
   </List>
   <List>
    <Link to="/tem-no-parque-conteudo">
     <img src={menu2} alt="Conteúdo da Página" className="conteudo" />
     <p>Conteúdo da Página</p>
    </Link>
   </List>
   <List>
    <Link to="/tem-no-parque-destaque">
     <img src={menu1} alt="Alterar Banners Destaques" />
     <p>Alterar Banners Destaques</p>
    </Link>
   </List>
   <List>
    <Link to="/tem-no-parque-galerias">
     <img src={menu1} alt="Alterar Galerias" />
     <p>Galerias</p>
    </Link>
   </List>
  </>
 )
}
